export default mirrorKeys({
  LOAD_PLAYERS        : null,
  LOAD_PLAYERS_SUCCESS: null,
  LOAD_PLAYERS_FAIL   : null,

  CREATE_PLAYER_SUCCESS: null,
  UPDATE_PLAYER_SUCCESS: null,
  REMOVE_PLAYER_SUCCESS: null,

  ON_PLAYER_CREATE: null,
  ON_PLAYER_UPDATE: null,
  ON_PLAYER_REMOVE: null,
});

function mirrorKeys(obj) {
  const mirroredObject = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      mirroredObject[key] = key
    }
  }

  return mirroredObject
}