import t from '../action-types'
import { reduceReducers, loadReducer, reducersMap } from './helpers'

const initialState = {
  list: []
};

const playersReducer = reduceReducers(initialState,
  loadReducer(t.LOAD_PLAYERS, (state, action) => ({
    ...state,
    list: action.result
  })),

  reducersMap({
    [t.CREATE_PLAYER_SUCCESS]: (state, action) => addPlayer(state, action.result),
    [t.UPDATE_PLAYER_SUCCESS]: (state, action) => updatePlayer(state, action.result),
    [t.REMOVE_PLAYER_SUCCESS]: (state, action) => deletePlayer(state, action.playerId),

    [t.ON_PLAYER_CREATE]: (state, action) => addPlayer(state, action.player),
    [t.ON_PLAYER_UPDATE]: (state, action) => updatePlayer(state, action.player),
    [t.ON_PLAYER_REMOVE]: (state, action) => deletePlayer(state, action.player.objectId)
  })
);

function addPlayer(state, player) {
  if (state.list.find(p => p.objectId === player.objectId)) {
    return state
  }

  return {
    ...state,
    list: state.list.concat(player)
  }
}

function updatePlayer(state, player) {
  return {
    ...state,
    list: state.list.map(p => p.objectId === player.objectId ? player : p)
  }
}

function deletePlayer(state, playerId) {
  return {
    ...state,
    list: state.list.filter(player => player.objectId !== playerId)
  }
}

export default playersReducer