import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Backendless from 'backendless';

import { addAlert } from '../alerts'
import { loadPlayers, getPlayers, onPlayerCreate, onPlayerUpdate, onPlayerRemove } from '../store';
//import  globalPlayerNumber  from '../globalvariables/globalvariables';

import create from 'zustand'

import Editor from './editor';
import DeleteConfirmation from './delete-confirmation';

import cx from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { createImportSpecifier } from 'typescript';

import { Link, animateScroll as scroll } from "react-scroll";
import { Scroll } from 'react-scroll/modules/mixins/Helpers';
//import { useState, useEffect} from 'react';



//import dotenv from 'dotenv';
//import useStore from '../zStore'  //using zStore rather than directly below:

var classNames = require('classnames');  //classnames allows us to use conditional rendering of css classes.



const mapStateToProps = state => {
  const { loading, loaded, error, list: players } = getPlayers(state);


//PlayerCount(players)
 //try to add this to the store?
 //const addPlayer = useStore(state => state.addPlayer)
 //addPlayer()

  return {
    loading,
    loaded,
    error,
    players
  }
};

/*
function PlayerCount(props){
  //addPlayer()
  //console.log("PlayerCount() playerCount : " + props );
  //const addPlayer = useStore(state => state.addPlayer)
}
*/





//let mapCount = 0; Jan 13 no longer used


class Players extends Component {

  //var sortedPlayerLength = 0;
//this.staticPlayerCount = sortedPlayers.length;





  state = {
    showEditor: false,
    editorProps: null,


    showDeleteConfirmation: false,
    deleteConfirmationProps: null,

    thePlayerInStore: "",
    thePlayerNewScore: false,
    showList: true,
    highlightedHobby: false,

    newrank: "",
    thePlayerInStorePreviousRank: "",

    //query string paramaters
    paramGameId: "",
    paramSubGameId: "",
    paramTournamentId: "",
    paramId: "", //the main Id
    paramPlayerId: "",

    paramActiveTournament: "",
    paramDate: "",
    paramTime: "",
    paramCourse: "",
    paramRank: "",
    paramPlaying: "",
    paramScoreVerified: "",
    paramShowCourse: "",

    numPlayers: 0,
    rankCount: 0

  };

  switch = () => {
    this.setState(prevState => ({
      showList: !prevState.showList
    }));
  };

  listSwitch = () => {
    this.setState(state => ({
      highlightedHobby: !state.highlightedHobby
    }));
  };

  


  showEditor = player => this.setState({ showEditor: true, editorProps: { player } });
  hideEditor = () => this.setState({ showEditor: false, editorProps: null });

  showDeleteConfirmation = player => this.setState({ showDeleteConfirmation: true, deleteConfirmationProps: { player } });
  hideDeleteConfirmation = () => this.setState({ showDeleteConfirmation: false, deleteConfirmationProps: null });


 datesInRange(d, start, end){
   // Checks if date in d is between dates in start and end.
        // Returns a boolean or NaN:
        //    true  : if d is between start and end (inclusive)
        //    false : if d is before start or after end
        //    NaN   : if one or more of the dates is illegal.
        // NOTE: The code inside isFinite does an assignment (=).
        return (
          isFinite(d.valueOf()) &&
          isFinite(start.valueOf()) &&
          isFinite(end.valueOf()) ?
          start <= d && d <= end :
          NaN
      );
}






  componentWillMount() {

this.publicVariable = 3;

    const queryGameId = new URLSearchParams(window.location.search).get("gameid")
    const querySubGameId = new URLSearchParams(window.location.search).get("subgameid")
    const queryPlayerId = new URLSearchParams(window.location.search).get("playerid")
    const queryTournamentId = new URLSearchParams(window.location.search).get("tournamentid")
    const queryId = new URLSearchParams(window.location.search).get("id")
    const queryActiveTournament = new URLSearchParams(window.location.search).get("activetournament")
    const queryDate = new URLSearchParams(window.location.search).get("date")
    const queryRank = new URLSearchParams(window.location.search).get("rank")
    const queryPlaying = new URLSearchParams(window.location.search).get("playing")
    const queryCourse = new URLSearchParams(window.location.search).get("course")
    const queryScoreVerified = new URLSearchParams(window.location.search).get("scoreverified")
    const queryShowCourse = new URLSearchParams(window.location.search).get("showcourse")
   //testRef = React.createRef();

    this.setState({
      paramGameId: queryGameId,
      paramSubGameId: querySubGameId,
      paramPlayerId: queryPlayerId,
      paramTournamentId: queryTournamentId,
      paramId: queryId,
      paramActiveTournament: queryActiveTournament,
      paramDate: queryDate,
      paramRank: queryRank,
      paramPlaying: queryPlaying,
      paramCourse: queryCourse,
      paramScoreVerified: queryScoreVerified,
      paramShowCourse: queryShowCourse,
      numPlayers : 5
    });

  
 


    console.log("ok here I am in component will mount, this.state.paramGameId is");
    console.log(this.state.paramGameId + ":" + queryGameId);

    console.log("ok here I am in component will mount, this.state.paramSubGameId is");
    console.log(this.state.paramSubGameId + ":" + querySubGameId);


    console.log("ok here I am in component will mount, this.state.paramPlayerId is");
    console.log(this.state.paramPlayerId + ":" + queryPlayerId);

    console.log("ok here I am in component will mount, this.state.paramTournamentId is");
    console.log(this.state.paramTournamentId + ":" + queryTournamentId);

    console.log("ok here I am in component will mount, this.state.paramActiveTournament is");
    console.log(this.state.paramActiveTournament + ":" + queryActiveTournament);


    console.log("ok here I am in component will mount, this.state.queryRank is");
    console.log(this.state.paramRank + ":" + queryRank);

    console.log("ok here I am in component will mount, this.state.queryPlaying is");
    console.log(this.state.paramPlaying + ":" + queryPlaying);


    console.log("ok here I am in component will mount, this.state.queryCourse is");
    console.log(this.state.paramCourse + ":" + queryCourse);

    console.log("ok here I am in component will mount, this.state.queryShowCourse is");
    console.log(this.state.paramShowCourse + ":" + queryShowCourse);

    this.props.loadPlayers(queryGameId, queryDate, queryRank, queryPlaying, queryCourse, queryScoreVerified, queryTournamentId, queryActiveTournament);




    this.playerRT = Backendless.Data.of('Gamifers').rt();

    this.playerRT.addCreateListener(this.onPlayerCreate);
    this.playerRT.addUpdateListener(this.onPlayerUpdate);
    this.playerRT.addDeleteListener(this.onPlayerRemove);


  }

  componentWillUnmount() {
    this.playerRT.removeCreateListener(this.onPlayerCreate);
    this.playerRT.removeUpdateListener(this.onPlayerUpdate);
    this.playerRT.removeDeleteListener(this.onPlayerRemove);
  }

  componentDidUpdate(){
    console.log("in component did update");

    //scrollToPlayer
    //working :-)

    console.log("ok here I am in component did update, this.state.paramPlayerId is");
    console.log(this.state.paramPlayerId);

    const playerId = this.state.paramPlayerId;
    console.log("playerId: " + playerId)

    
   
    const element = document.getElementById(playerId);
    console.log("element is")
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }


    //or if using library react-scroll try this.
    /*for now I can't get this to work - usng the react-scroll library
    scroll.scrollTo('DC7412F1-CEB1-4E3B-9D27-655C40897A86', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 10,
      containerId: 'ContainerElementID'
    })*/

    //scroll.scrollToBottom();

    
/*
    scroll.scrollTo('F2D1056-55EC-4B4C-9310-4257FE85B26CD', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 10
    })
*/

  }



  onPlayerRemove = player => this.props.onPlayerRemove(player)

  onPlayerCreate = player => {
    //const { players } = this.props //pretty sure this is unncesscary but to begin with newly created players were not comng up then 
    //i put this in and it worked, then i took it out and it still worked!! keep out until otherwise proven needed.

    console.log("Player created");
    console.log("in onPlayerCreate");
    console.log(player.objectId);
    console.log("gameId is:")
    console.log(player.gameId);
    console.log(player.tournamentId);
    console.log(player.activeTournament);
    console.log("ok this.state.paramGameId is");
    console.log(this.state.paramGameId);

    console.log("ok this.state.paramTournamentId is");
    console.log(this.state.paramTournamentId);

    console.log("ok this.state.paramActiveTournament is");
    console.log(this.state.paramActiveTournament);

    console.log("ok this.state.paramRank is");
    console.log(this.state.paramRank);

    console.log("ok this.state.paramCourse is");
    console.log(this.state.paramCourse);


//april182023
/*
if(player.gamingStatus == "error"){
  console.log("this player is in error, we do not want it");
  return;
}
*/


 //if we have are using a activetournament in the paramater and this player does not match that game , reject:
 if (player.activeTournament == false) {
  console.log("this player does not match our query active tournament therefore we are not adding this player!");
  return;

}



    if (this.state.paramRank) {
      console.log("yep we have a paramRank");
    } else {
      console.log("no we don't have a paramRank");
    }



    if (this.state.paramGameId) {
      //if we have are using a gameid in the paramater and this player does not match that game , reject:
      if (player.gameId != this.state.paramGameId) {
        console.log("this player does not match our query gameid therefore we are not adding this player!");
        return;
      }
    }



    if (this.state.paramTournamentId) {
      //if we have are using a tournamentid in the paramater and this player does not match that game , reject:
      if (player.tournamentId != this.state.paramTournamentId) {
        console.log("this player does not match our query tournamentid therefore we are not adding this player!");
        return;
      }
    }


    if (this.state.paramActiveTournament) {
      //if we have are using a activetournament in the paramater and this player does not match that game , reject:
      if (player.activeTournament != this.state.paramActiveTournament) {
        console.log("this player does not match our query active tournament therefore we are not adding this player!");
        return;
      }
    }


     


    if (this.state.paramCourse) {
      //if we have are using a course in the paramater and this player does not match that game , reject:
      if (player.courseName != this.state.paramCourse) {
        console.log("this player does not match our query courseName therefore we are not adding this player!");
        return;
      }
    }

   


    if (player.gameId == 'New') {
      //work out the ones we don't want here!
      console.log("oh yean we don't want this player here!!");
      return;
      //or date , or any other query/where clause which is in operation in the querystrings.
    }

    this.props.onPlayerCreate(player)
  }


  onPlayerUpdate = player => {
    const { players } = this.props

    


    console.log("onPlayerUpdate: " + player.objectId);
    //set this as a class variable??

    const playerInStore = players.find(p => p.objectId === player.objectId)
    //asign this to state (bit like a class variable)

    if (!playerInStore) {
      //v2.4 added this early return
      //if we have used a where clause in the original load then this player
      //cannot be found and it will throw all sorts of errors therefore I am completely ignoring it
      //not adding it to the store or anything. Seems to be ok!! Not sure about redux rules or anything!!
      console.log("oops we can't find this player!!!");
      
      //for the future if we can't find this player, yet it satisfied the paramaters it should in fact be added.  
      //this.props.onPlayerCreate(player) //MARCH2022 - LIVEPARAMFIX


      var addPlayer = true;


      //april 2023
      /*
      if (player.gamingStatus == 'error'){
        console.log("this player is in error, do not show");
        addPlayer = false
      }*/

  
 //Aug 2022 , irrespective of if we have paramActiveTournament we do not want to show the non active tournaments
   // except if the tournament id is in use.
   if (player.activeTournament == 'false') {
    console.log("this player not in active tournament do not show.!");
    //this.props.onPlayerRemove(player)
    addPlayer = false
    //return;  //not sure if we should have this return in or not???
    }




      if (this.state.paramTournamentId) {
        if (player.tournamentId != this.state.paramTournamentId) {
          console.log("this player does  match our query tournamentid therefore we want to add this player!");
          //this.props.onPlayerCreate(player)
          //return;  //not sure if we should have this return in or not???
          addPlayer = false
          }
      }


      if (this.state.paramActiveTournament) {
        //if we have are using paramActiveTournament in the paramater and this player does not match that game , reject:
             if (player.activeTournament != this.state.paramActiveTournament) {
              console.log("this player does match our query tournamentid therefore we want to add player!");
              //this.props.onPlayerCreate(player)
              //return;  //not sure if we should have this return in or not???
              addPlayer = false
              }
      }






      if (this.state.paramGameId) {
        //if we have are using a gameid in the paramater and this player does not match that game , reject:
        if (player.gameId != this.state.paramGameId) {
          console.log("this player does match our query gameid therefore we want to add this player!");
          //this.props.onPlayerCreate(player)
          //return;  //not sure if we should have this or not?!
          addPlayer = false
        }
      }

      if (this.state.paramCourse) {
        //if we have are using a course in the paramater and this player does not match that game , reject:
        if (player.courseName != this.state.paramCourse) {
          console.log("this player does match our query courseName therefore we want to add this player!");
          //this.props.onPlayerCreate(player)
          //return;
          addPlayer = false
        }
      }


     //hardest one date
     if (this.state.paramDate) {
      //if we have are using a date in the paramater and this player does not match that game , reject:
  
          console.log("param date  is " + this.state.paramDate);
          console.log("player date is " + player.dateTime)
  
   
        var playerDate = new Date(player.dateTime)
        playerDate.setHours(0,0,0,0);
  
        //convert the param date to epoch
        //start easy a simple format e.g 18/03/2022
  
        var formattedDays;
        var dateStart;
        var dateFinish;
  
        if(this.state.paramDate.includes('-')){
            //2 dates set.
            var datesArray = this.state.paramDate.split("-");
           var formattedDays1 = datesArray[0].split("/");
           var formattedDays2 = datesArray[1].split("/");
            dateStart = new Date(formattedDays1[2], formattedDays1[1] - 1, formattedDays1[0],0,0,0,0)
            dateFinish = new Date(formattedDays2[2], formattedDays2[1] - 1, formattedDays2[0],0,0,0,0)
  
        }
  
        if(!this.state.paramDate.includes('-') && this.state.paramDate!='today'){
        //standard param eg. 21/12/2022
        formattedDays = this.state.paramDate.split("/");
        dateStart = new Date(formattedDays[2], formattedDays[1] - 1, formattedDays[0],0,0,0,0)
        dateFinish = dateStart
        }
  
        if(this.state.paramDate == 'today'){
          dateStart = new Date().setHours(0,0,0,0); //today
          dateFinish = dateStart;
        }
  
  
  
            if (!this.datesInRange(playerDate, dateStart, dateFinish)) {
              console.log("timing out of search range, this player does not match our query date therefore do not add this player!");
                addPlayer = false
            }else{
              console.log("timing in range")
            }
          }


     //end hardest one date





      //if all the filters are met. and player not in store.
      if(addPlayer == true){
        this.props.onPlayerCreate(player)

    

       

      }


      return; //
    }


   //march 2022 = LIVEPARAMFIX if the player does not match query params then remove
   //so prior to this update if a field (say tournamentid or gameid) was changed in the database and we were wanting to show a particular tournament ?tournamentid = current
   //then this would only picked up if you refreshed the browser. Now the app will see the change when a update to the player occurs and will remove the player if it does not
   //match the original search criteria.


//april 2023
if(player.gamingStatus == 'error'){
  //this player is in error we do not want to show
  console.log("this player is now in error - do nt show!");
  this.props.onPlayerRemove(player)
}

  
 //Aug 2022 , irrespective of if we have paramActiveTournament we do not want to show the non active tournaments
   // except if the tournament id is in use.
   if (player.activeTournament == 'false') {
    console.log("this player not in active tournament do not show.!");
    this.props.onPlayerRemove(player)
    //return;  //not sure if we should have this return in or not???
    }

   
   if (this.state.paramTournamentId) {
    //if we have are using a tournamentid in the paramater and this player does not match that game , reject:

 
       
        if (player.tournamentId != this.state.paramTournamentId) {
          console.log("this player does not match our query tournamentid therefore we want to remove player!");
          this.props.onPlayerRemove(player)
          //return;  //not sure if we should have this return in or not???
          }
      }
    

      if (this.state.paramActiveTournament) {
        //if we have are using paramActiveTournament in the paramater and this player does not match that game , reject:
             if (player.activeTournament != this.state.paramActiveTournament) {
              console.log("this player does not match our query tournamentid therefore we want to remove player!");
              this.props.onPlayerRemove(player)
              //return;  //not sure if we should have this return in or not???
              }
      }

     



  

  if (this.state.paramGameId) {
    //if we have are using a gameid in the paramater and this player does not match that game , reject:
    if (player.gameId != this.state.paramGameId) {
      console.log("this player does not match our query gameid therefore we want to remove this player!");
      this.props.onPlayerRemove(player)
      //return;  //not sure if we should have this or not?!
    }
  }

  if (this.state.paramCourse) {
    //if we have are using a course in the paramater and this player does not match that game , reject:
    if (player.courseName != this.state.paramCourse) {
      console.log("this player does not match our query courseName therefore we want to remove this player!");
      this.props.onPlayerRemove(player)
      //return;
    }
  }


  if (this.state.paramDate) {
    //if we have are using a date in the paramater and this player does not match that game , reject:

        console.log("param date  is " + this.state.paramDate);
        console.log("player date is " + player.dateTime)



      //player.dateTime is epoch
      //this.state.paramDate is format like 18/03/2022 or 17/03/2022-18/03/2022 or today

      //we need to see if the player.dateTime is within the paramDate range!

      var playerDate = new Date(player.dateTime)
      playerDate.setHours(0,0,0,0);

      //convert the param date to epoch
      //start easy a simple format e.g 18/03/2022

      var formattedDays;
      var dateStart;
      var dateFinish;

      if(this.state.paramDate.includes('-')){
          //2 dates set.
          var datesArray = this.state.paramDate.split("-");
         var formattedDays1 = datesArray[0].split("/");
         var formattedDays2 = datesArray[1].split("/");
          dateStart = new Date(formattedDays1[2], formattedDays1[1] - 1, formattedDays1[0],0,0,0,0)
          dateFinish = new Date(formattedDays2[2], formattedDays2[1] - 1, formattedDays2[0],0,0,0,0)

      }

      if(!this.state.paramDate.includes('-') && this.state.paramDate!='today'){
      //standard param eg. 21/12/2022
      formattedDays = this.state.paramDate.split("/");
      dateStart = new Date(formattedDays[2], formattedDays[1] - 1, formattedDays[0],0,0,0,0)
      dateFinish = dateStart
      }

      if(this.state.paramDate == 'today'){
        dateStart = new Date().setHours(0,0,0,0); //today
        dateFinish = dateStart;
      }


      //var epochStart  = new Date(2022,2,19,0,0,0,0)

      console.log("timing: playerDate = " + playerDate);
      console.log("timing: dateStart = " + dateStart);
      console.log("timing: dateStart = " + dateStart);

          if (!this.datesInRange(playerDate, dateStart, dateFinish)) {
            console.log("timing out of search range, this player does not match our query date therefore we want to remove this player!");
            this.props.onPlayerRemove(player)
            // return;
          }else{
            console.log("timing in range")
          }
        }

//march updates.



    const sendAlert = (playerInStore, player) => {
      if (playerInStore && player.name !== playerInStore.name) {
        addAlert(`Player with name "${playerInStore.name}" has changed his name to "${player.name}"!`)
      }

      /*
      if (playerInStore && player.hole1 !== playerInStore.hole1 && player.hole1 == 1) {
        addAlert(`Player with name "${playerInStore.name}" has just got a hole in one! "${player.hole1}"! 
        '<br>'Mike is cool`)
      }
      */
      let newHtmlStr = `${playerInStore.name} - an Ace!!`;



      if (playerInStore && player.hole1 !== playerInStore.hole1 && player.hole1 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole2 !== playerInStore.hole2 && player.hole2 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole3 !== playerInStore.hole3 && player.hole3 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole4 !== playerInStore.hole4 && player.hole4 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole5 !== playerInStore.hole5 && player.hole5 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole6 !== playerInStore.hole6 && player.hole6 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole7 !== playerInStore.hole7 && player.hole7 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole8 !== playerInStore.hole8 && player.hole8 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole9 !== playerInStore.hole9 && player.hole9 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole10 !== playerInStore.hole10 && player.hole10 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole11 !== playerInStore.hole11 && player.hole11 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole12 !== playerInStore.hole12 && player.hole12 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole13 !== playerInStore.hole13 && player.hole13 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole14 !== playerInStore.hole14 && player.hole14 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole15 !== playerInStore.hole15 && player.hole15 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole16 !== playerInStore.hole16 && player.hole16 == 1) {
        addAlert(newHtmlStr);
      }

      if (playerInStore && player.hole17 !== playerInStore.hole17 && player.hole17 == 1) {
        addAlert(newHtmlStr);
      }
      if (playerInStore && player.hole18 !== playerInStore.hole18 && player.hole18 == 1) {
        addAlert(newHtmlStr);
      }

      let wellPlayedString = `Thanks for playing ${playerInStore.name} - you scored ${player.score}`;
      if (playerInStore && player.hole18 !== playerInStore.hole18) {
        addAlert(wellPlayedString);
      }

    }



    this.setState({
      thePlayerInStorePreviousRank: playerInStore.rank
    });

    this.setState({
      thePlayerInStore: playerInStore.objectId
    });

    sendAlert(playerInStore, player)






    this.props.onPlayerUpdate(player)
  }

  onAddClick = () => this.showEditor(null);
  onEditClick = player => this.showEditor(player);
  onDeleteClick = player => this.showDeleteConfirmation(player);




  renderPlayer = player => {


    // mapCount += 1; Jan13 no longer used


    //we have the player.objectId
    // console.log("in render: " + this.state.thePlayerInStore);
    console.log("in render, player rank:" + player.rank)
    console.log(player.name)
    console.log(player.objectId)
console.log("player course:");
console.log(player.courseName);


    // console.log("in render rank (mapCount): " + mapCount);






    //console.log(player.objectId);

    let isThisPlayerChanged = false;
    let isThisPlayerChangedText = "FALSE MATE";

    let previousRank = 0;
    let rankMove = "";
    //let totalScore = player.hole1 + player.hole2;
    //return <i className={'fas fa-chevron-circle-up rankMoveUp'}></i> 
    //        return <i className={'fas fa-arrow-down rankMoveDown'}></i>  

    const renderCourseIcon = () => {
      if(this.state.paramShowCourse == 'true'){

        if(player.courseName === "Pirate Golf"){
          return <img src="course_logo_ha_pirate.png" class="courseLogoSize"></img>
        }
        if(player.courseName === "Adventure Golf"){
          return <img src="course_logo_ha_adventure.png" class="courseLogoSize"></img>
        }
        if(player.courseName === "Crazy Golf"){
          return <img src="course_logo_ha_crazy.png" class="courseLogoSize"></img>
        }
        
      }else{
        return <p></p>
      }
      
    }



    const renderPlayerName = () => {


        //currently displays either image, flag or nothing. cannot display image and flag.



      var flag = "none";
     
      if (player.nationality && !player.image){
      
              var nationality = "";
              nationality = player.nationality;
              nationality = nationality.toUpperCase();
            
            if (nationality == "ENGLAND"){
              flag = "England.png"
            }else if (nationality == "SCOTLAND"){
              flag = "Scotland.png"
            }else if (nationality == "WALES"){
              flag = "Wales.png"
            }else if (nationality == "AMERICA"){
              flag = "USA.png"
            }else if (nationality == "USA"){
              flag = "USA.png"
            }else if (nationality == "PORTUGAL"){
              flag = "Portugal.png"
            }else if (nationality == "CZECH REPUBLIC"){
              flag = "czechrepublic.png"
            }else if (nationality == "IRELAND"){
              flag = "Ireland.png"
            }else if (nationality == "NEW ZEALAND"){
              flag = "NZ.png"
            }else if (nationality == "HUNGARY"){
              flag = "Hungary.png"
            }else if (nationality == "AUSTRALIA"){
              flag = "Australia.png"
            }else if (nationality == "IRELAND"){
              flag = "Ireland.png"
            }else if (nationality == "AOTEOROA"){
              flag = "NZ.png"
            }else if (nationality == "NEW ZEALAND"){
              flag = "NZ.png"
            }else if (nationality == "SPAIN"){
              flag = "spain.png"
            }

        
            if (flag != ""){
              return <div class="aligntextcentercenter"><img src={'images/flags/' + flag} class="flagSize"></img><span class="pl-2 ">{player.name}</span></div>
            }
            if (flag == "none"){
              //don't know this country yet
              return player.name
            }
            


    }



if (!player.nationality && player.image){

  let imageName = 'images/users/' + player.image
  return <div class="aligntextcentercenter"><img src={imageName} class="playerImageSize"></img><span class="pl-2 ">{player.name}</span></div>
     

      }



      if (!player.nationality && !player.image){

       return player.name
           
      
            }


      
     





    }


    const renderRankIcon = () => {
    
      if (player.holesPlayed > 0){
          if (rankMove == 'up') {
          return <img src="up_arrow.png" class="rankSize rankUpDownActive"></img>
        }

        if (rankMove == 'down') {
          return <img src="down_arrow.png" class="rankSize rankUpDownActive"></img>
        }
      }
      
    

      if(player.holesPlayed == 0 && player.status){
        //show booking ref if holes played is 0
        return  <div className={' fontFace2 colorStatus  size1BitSmaller pl-1 h-100 rounded aligntextcentercenter'}  >{player.status}</div>
      }


      if(player.holesPlayed == 0 && !player.status){
        //show booking ref if holes played is 0
        return <span></span>
      }


    }



    const renderRank = () => {
     

        if(player.holesPlayed > 0){

          return <div className={cx("rankCircleSize aligntextcentercenter fontFace1", { "playerActive": false, "player3DSpin": true })} >
            <span className="cx({ playerActive: false })}">{player.rank}</span>
          </div>
        }

        if(player.holesPlayed == 0 && !player.status ){

          return <div className={cx("rankCircleSize aligntextcentercenter fontFace1", { "playerActive": false, "player3DSpin": true })} >
            <span className="cx({ playerActive: false })}">{player.rank}</span>
          </div>

        }
    

        if(player.holesPlayed == 0 && player.status ){

          return <span></span>

        }


      
    }

    const renderCol2Width = () =>{
      //if holes played is zero we don't show rank up / down column (column 2) and use invisible class
      //make the player rank column (column 3) a width of two and show the booking ref instead.

      //12May update - added - we do not render the course icon in xs or s classes  this is what d-sm-none d-md-block does

       if(player.holesPlayed == 0 && player.status ){
         return "col-2" 
       }
       
       if(!player.status ){
        return "col-1"
      }
       
       if(player.holesPlayed > 0){
        return "col-1"
      }
     


    }

    const renderCol3Width = () =>{
      //if holes played is zero we don't show rank up / down column (column 2) and use invisible class
      //make the player rank column (column 3) a width of two and show the booking ref instead.
      if(player.holesPlayed == 0  && player.status){
        return "invisible"
      }

      if(!player.status > 0){
        return "col-1"
      }

      if(player.holesPlayed > 0){
        return "col-1"
      }


     
  }






   


    if (this.state.thePlayerInStore == player.objectId) {
      isThisPlayerChanged = true;
      isThisPlayerChangedText = "TRUE MATE";
      previousRank = this.state.thePlayerInStorePreviousRank;

      if (player.rank < previousRank) {
        rankMove = 'up';
      }

      if (player.rank > previousRank) {
        rankMove = 'down';
      }

      if (player.rank == previousRank) {
        rankMove = '-';
      }

      // totalScore = player.hole1 + player.hole2;




    }




    const getScoreForHole = (player) => {
      var scoreForHole;
      if (player.holesPlayed == 1) {
        scoreForHole = player.hole1;
      }
      if (player.holesPlayed == 2) {
        scoreForHole = player.hole2;
      }
      if (player.holesPlayed == 3) {
        scoreForHole = player.hole3;
      }
      if (player.holesPlayed == 4) {
        scoreForHole = player.hole4;
      }
      if (player.holesPlayed == 5) {
        scoreForHole = player.hole5;
      }
      if (player.holesPlayed == 6) {
        scoreForHole = player.hole6;
      }
      if (player.holesPlayed == 7) {
        scoreForHole = player.hole7;
      }
      if (player.holesPlayed == 8) {
        scoreForHole = player.hole8;
      }
      if (player.holesPlayed == 9) {
        scoreForHole = player.hole9;
      }
      if (player.holesPlayed == 10) {
        scoreForHole = player.hole10;
      }
      if (player.holesPlayed == 11) {
        scoreForHole = player.hole11;
      }
      if (player.holesPlayed == 12) {
        scoreForHole = player.hole12;
      }
      if (player.holesPlayed == 13) {
        scoreForHole = player.hole13;
      }
      if (player.holesPlayed == 14) {
        scoreForHole = player.hole14;
      }
      if (player.holesPlayed == 15) {
        scoreForHole = player.hole15;
      }
      if (player.holesPlayed == 16) {
        scoreForHole = player.hole16;
      }
      if (player.holesPlayed == 17) {
        scoreForHole = player.hole17;
      }
      if (player.holesPlayed == 18) {
        scoreForHole = player.hole18;
      }


      return scoreForHole
    }


    const isAlive = (holesPlayed, startDate, updatedDate) => {
      // const today = new Date()

      console.log("startDate is:" + startDate);
      console.log("updatedDate is" + updatedDate);

      var isAliveStill = true;

      var diffMs = (updatedDate - startDate); // milliseconds between now & start time
      var diffDays = Math.floor(diffMs / 86400000); // days
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

      var totalMinsElapsed = (diffDays * (24 * 60)) + (diffHrs * 60) + diffMins;

      const timeNow = new Date()


      //it could be best to avoid timenow as times on browsers maybe all over the place. however
      //I can't seem to avoid this as if player is never updated we can't tell this.
      //should be ok I think , both dates are in british summer time.


      var updatedDateToLocal;
      updatedDateToLocal = new Date(updatedDate);
      console.log("dates hey!!")

      console.log(updatedDate);
      console.log("updatedDateToLocal:" + updatedDateToLocal);
      console.log("timeNow:" + timeNow);



      var diffMs2 = (timeNow - updatedDateToLocal); // milliseconds between now & Christmas
      //var diffDays = Math.floor(diffMs / 86400000); // days
      //var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      var diffMins2 = Math.round(((diffMs2 % 86400000) % 3600000) / 60000);

      // var totalMinsSinceTimeNowAndWhenLastUpdated = diffMins2

      //diffMins = time in mins between updated date and start
      //diffMins2 = timenow and updated 

      var minsDead = 0

      console.log("holesPlayed : diffMins : DiffMins2")
      console.log(holesPlayed + " " + diffMins + " " + diffMins2)


      if ((holesPlayed >= 0 && holesPlayed < 18) && diffMins2 > 20) {
        //it has been more than 20 mins  since time now and when this value was last updated
        //delete this player so the leaderboard stays fresh.
        isAliveStill = false
      }



      console.log(totalMinsElapsed);
      console.log(isAliveStill);

      //  return someDate.getDate() == today.getDate() &&
      //   someDate.getMonth() == today.getMonth() &&
      //   someDate.getFullYear() == today.getFullYear()

      return isAliveStill

    }


   


    const isPlaying = (someDate) => {
      const today = new Date()

      var diffMs = (today - someDate); // milliseconds between now & someDate (player start time)
      var diffDays = Math.floor(diffMs / 86400000); // days
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

      var totalMins = (diffDays * (24 * 60)) + (diffHrs * 60) + diffMins;



      console.log("Difference in mins is:");
      //console.log(diffDays + ":" + ":" + diffHrs + ":" + diffMins);
      console.log(totalMins)

      //  return someDate.getDate() == today.getDate() &&
      //   someDate.getMonth() == today.getMonth() &&
      //   someDate.getFullYear() == today.getFullYear()

      return totalMins

    }

const testref = React.createRef();
//const testRef = useRef();
  

    const scrollToPlayer = () => {
      /*scroll.scrollTo('DF2D1056-55EC-4B4C-9310-4257FE85B26C', {
        duration: 1500,
        delay: 0,
        smooth: true,
        to: 'DF2D1056-55EC-4B4C-9310-4257FE85B26C',
        offset: 100, // Scrolls to element + 50 pixels down the page
     
      })
      */

     // const element = document.getElementById('DF2D1056-55EC-4B4C-9310-4257FE85B26C')
     // if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        //element.scrollIntoView({ behavior: 'smooth' });
     // }


/*
      scroll.scrollTo('F2D1056-55EC-4B4C-9310-4257FE85B26CD', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: 10
      })
    
*/

    // scroll.scrollToBottom()

    const element = document.getElementById('mike');
    console.log("element is")
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }


    console.log("testref is ")
    console.log(testref)

   if (!testref.current) { return }

   
      testref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });

    };

    



    const renderTheScore = () => {

  
      this.state.rankCount = this.state.rankCount + 1;

      // console.log("in renderTheScore and param rank is ");
      // console.log(this.state.paramRank);


      //do not show unverified score if scoreverified paramater is set
      //done now by removing from array before teh sort.
      /*
      if(this.state.paramScoreVerified){
        if (player.verified != 'TRUE'){
          //do not show
          return;
        }
      }
      */


      if (this.state.paramPlaying) {
        console.log("yes we have param playing");
        //there are no options for playing - the default is live so just use that.
        //if query param is live - TO DO!!
        const startTime = new Date(player.dateTime)
        const timeSinceStart = isPlaying(startTime)
        console.log("is it live?");

        if (timeSinceStart > 120) {   //120 mins
          console.log("not in play")

          //this player is not live (more than 120 mins) so we reject this player from showing
          //by adding a return here.
          return;
        }
      }

      if (this.state.paramRank) {
        let rankparam = this.state.paramRank
        var startRank = rankparam.substr(0, rankparam.indexOf('-'));
        var endRank = rankparam.substr(rankparam.indexOf('-') + 1);


        // console.log(startRank);
        // console.log(endRank);

        //for v2.6 trying rank a better way - see notes on why.
        //right rank is done in rank order, ok.
        //what number of loop are we on
        // console.log("rankCount is ");
        //console.log(this.state.rankCount);

        // 2.6 way - not sure if this works yet!
        /*
          if(this.state.rankCount < startRank){
            return;
          }
    
          if(this.state.rankCount > endRank){
            return;
          }
    */


        


        //2.5 way (working)
        if (player.rank < startRank) {
          return;
        }

        if (player.rank > endRank) {
          return;
        }



      }













      //now we need something to delete those players who shouldn't be on the leaderboard
      console.log("players hole played:");
      console.log(player.holesPlayed);
      var dateSinceLastUpdate;
      
      /* Aug 2022 taken out - we will move this into a pipedream workflow and check every 20 mins or so.
      if (player.holesPlayed < 18) {

        console.log("what is the latest updated time:" + player.updated);

       //if the player has just been created and not upated yet (ie no score changed)
          then we have no updated value and we should use created date instead.
          
    
        if (!player.updated) {
          console.log("no update yet, using careated");
          dateSinceLastUpdate = player.dateTime;
        } else {
          dateSinceLastUpdate = player.updated;
        }

        //if the score has not been updated within 20 mins of it being created then we presume something has gone badly wrong
        const isStillAlive = isAlive(player.holesPlayed, player.dateTime, dateSinceLastUpdate);
        if (isStillAlive == false) {//

          console.log("player has not finished and time since last update is");
          console.log(player.name)
          console.log(player.objectId);
          console.log("we should delete this player!");

          //permanently delete. not deleting yet to try and help debug if any weird scores
          Backendless.Data.of('Gamifers').remove(player.objectId);
          return;  //reject this player
        }
      }

*/



      //timeframe / date.
      /*
      if(player.name == "max"){
        return;
      }
      */


      var strPar = "";
      if (player.parScore > 0) {
        strPar = "+" + player.parScore;
      }
      if (player.parScore < 0) {
        strPar = player.parScore;
      }
      if (player.parScore == 0) {
        strPar = "E";
      }

      var scoreForHole = 0;
      scoreForHole = getScoreForHole(player)


      var playerName = "";
      playerName = player.name;
      playerName = playerName.toUpperCase();

      var boGoodScore = true;
      if (scoreForHole > 2) {
        boGoodScore = false
      }

      var colScoreForHoleClasses = classNames(
        'fontFace1',
        'size2',
        'h-100',
        'rounded',
        'aligntextcentercenter',
        {
          'scoreForHoleGoodActive': boGoodScore,
          'scoreForHoleBadActive': !boGoodScore
        }

      );

//take out element stuff if buggy!
var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;

      if (isThisPlayerChanged) {

        //13 Jan 2020 mapCount replaced with player.rank

        return <div key={Math.random()} className={'row p-1'} id={player.objectId} >


    {/*May 2023 we do not render the course icon in xs or s classes  this is what d-none d-md-block does */}
          <div className={'col-1 d-none d-md-block'}>{renderCourseIcon()} 
         
        
          </div>



          <div className={cx(renderCol2Width())}>    {renderRankIcon()}   </div>

      

          <div className={cx(renderCol3Width(),'text-center')}>
           
             {renderRank()}
           
          </div>

  {/* may2023 becasue we hide the first column on small screens if we remove the col-4 and make it just col then this
          will have the effect of making the player name column use that extra column if available*/}
          <div className={'col '}>
            <div className={'fontFace1 size0 pl-2 h-100 cellBackgroundColor rounded aligntextcenterleft uppercase'}  >
            {renderPlayerName()} 
            </div>
          </div>

          <div className={'col-1 '} >
            <div className={'fontFace1 size2 colLeft1 h-100 cellBackgroundColor rounded aligntextcentercenter '}  >
              {player.holesPlayed}
            </div>
          </div>


          <div className={'col-1 '}>
            <div className={'fontFace1 size2  h-100 cellBackgroundColor rounded aligntextcentercenter '}  >
              {player.score}
            </div>
          </div>


          <div className={'col-1'}>
            <div className={cx('fontFace1 size2 colRight1 h-100 cellBackgroundColor rounded aligntextcentercenter', { "playerActive": false })}  >
              {strPar}
            </div>
          </div>




          <div className={'col-1 colRight2'}>
            <div className={colScoreForHoleClasses}  >
              {scoreForHole}
            </div>
          </div>

          <div className={'col-1 d-none d-md-block'}>

          </div>



        </div>

      } else {

        



        return <div key={Math.random()} className={'row rowHeight p-1 '} id={player.objectId} >


          {/*may 2023 we do not render the course icon in xs or s classes  this is what d-none d-md-block does */}
          <div className={'col-1 d-none d-md-block'}>{renderCourseIcon()}</div> 

          <div className={cx(renderCol2Width())}>{renderRankIcon()}

          </div>


          <div className={cx(renderCol3Width() , ' text-center')}>
             {renderRank()} 
          </div>




          {/* may2023 becasue we hide the first column on small screens if we rename col-4 to just col then this
          will have the effect of making the player name column use that extra column if available*/}
          <div className={'col'}  >
            <div className={'fontFace1 size0 pl-2 rowHeight cellBackgroundColor rounded aligntextcenterleft uppercase'}  >
              {renderPlayerName()} 
            </div>
          </div>

          <div className={'col-1'}  >
            <div className={'fontFace1 size2 colLeft1 h-100 cellBackgroundColor rounded aligntextcentercenter'}  >
              {player.holesPlayed}
            </div>
          </div>

          <div className={'col-1'}>
            <div className={'fontFace1 size2   h-100 cellBackgroundColor rounded aligntextcentercenter '}  >
              {player.score}
            </div>
          </div>


          <div className={'col-1 '}>
            <div className={cx('fontFace1 size2 colRight1  h-100 cellBackgroundColor rounded aligntextcentercenter', { "playerActive": false })}  >
              {strPar}
            </div>
          </div>

          <div className={'col-1 '}>
            <div className={cx('fontFace1 size2 colRight2 p-1 h-100  rounded aligntextcentercenter ')}  >

            </div>
          </div>

{/*hide if on small screens */ }
          <div className={'col-1 d-none d-md-block'}>

          </div>


          {/* 

         
         

       <span className={'sizeH1'}> rank: {player.rank}, previousRank: {previousRank} , {player.name}, Par Score - {player.parScore} </span>
      */}

        </div>
      }
    }



    const renderScore = () => {
      if (isThisPlayerChanged) {
        return <p class="newScore"> Just Scored  {player.parScore}</p>
      } else {

        return <p>Old Score {player.parScore}</p>
      }
    }

    const renderScore2 = () => {
      if (isThisPlayerChanged) {

        return <p key={Math.random()} className={cx({ "playerActive": true })}>
          renderScore2 - player change - {player.parScore} </p>

      } else {

        return <p key={Math.random()} className={cx({ "playerActive": false })}>
          renderScore2 - player no change - {player.parScore} </p>
      }
    }

    const renderScore2A = () => {
      if (isThisPlayerChanged) {

        return <p className={cx({ "playerGreen": true })}>
          renderScore2A - player change - {player.parScore} </p>

      } else {

        return <p className={cx({ "playerBlue": true })}>
          renderScore2A - player no change - {player.parScore} </p>
      }
    }
    const renderScore4 = () => {
      if (isThisPlayerChanged) {

        return <div key={Math.random()} className={cx({ "colorBlue": true, "player3DSpin": true })} style={{ width: '60px', height: '60px' }}><p >
          rs4 - {player.parScore} </p></div>

      } else {

        return <div key={Math.random()} className={cx({ "colorBlue": true, "player3Dspin": false })} style={{ width: '60px', height: '60px' }}><p >
          rs4  - {player.parScore} </p></div>
      }
    }



    const renderScore3 = () => {
      if (isThisPlayerChanged) {

        return <p className={cx({ "roll-active": true })}>
          renderScore3 - player change - {player.parScore} </p>

      } else {

        return <p className={cx({ "roll-exit": true })}>
          renderScore3 - player change - {player.parScore} </p>
      }
    }



    return (






      <div key={player.objectId} >



        {/*

<p className={cx("list-item", {"playerChanged": isThisPlayerChanged})}>
{player.parScore} </p>

<p className={cx( {"playerChanged": isThisPlayerChanged})}>
{player.parScore} </p>


*/}

        {renderTheScore()}
      





      </div>





    );
  };






  render() {
    //mapCount = 0; Jan 13 no longer used
    const { loading, error, players } = this.props;
    const { showEditor, editorProps, showDeleteConfirmation, deleteConfirmationProps } = this.state;



    //const { players } = this.props

    //const playerInStore = players.find(p => p.objectId === player.objectId)
    //console.log(this.props);



    //new sort function 2020
    let sortedPlayers = [...players];

//this.staticPlayerCount = sortedPlayers.length;
//static staticPlayerCount = 7;


    
        //const addPlayer = useStore(state => state.addPlayer)
        //const addPlayer = useStore.setState(state => state.addPlayer)
//addPlayer()

       // addPlayer()
       //const { addPlayer } = this.props.store;
      /* const useStore = create(() => ({ isAwesome: true }))
       const state = useStore.getState()
console.log(state.isAwsome)
useStore.setState({ isAwsome: false })
*/


    //new oct 2022
  //numberOfPlayers = 25;
// this.setState({ numPlayers : 5})

/*
const useStore = create((set) => ({
  playerCount: 25,
 
 
}))
*/



//const playerCount = useStore(state => state.playerCount)
//console.log("playerCount")

    //if we are using some paramaters it is best to exlude the results here
    //rather than in the render.

    //lets exlude the ones without a verified score from the array if the scoreverified = true is set
    console.log("filtering the array first time")
    if (this.state.paramScoreVerified) {

      /*
      sortedPlayers.forEach(function (arrayItem) {
        console.log(arrayItem.name);
        console.log(arrayItem.verified)});
   */


      var i = sortedPlayers.length
      while (i--) {
        console.log(sortedPlayers[i].name);
        console.log(sortedPlayers[i].verified);

        if (sortedPlayers[i].verified != "TRUE") {
          sortedPlayers.splice(i, 1);
        }

        //Auction.auctions.splice(i, 1);

      }



    }

   //Jan 2023 remove DEAD players

   var i2 = sortedPlayers.length
   console.log("filtering the array for dead players." + sortedPlayers.length)
    while (i2--) {
      if (sortedPlayers[i2].gamingStatus == "error") {
          sortedPlayers.splice(i2, 1);
      }
    }






    sortedPlayers.sort((a, b) => {
      if (a.parScore < b.parScore) {
        return -1;
      }
      if (a.parScore > b.parScore) {
        return 1;
      }

     //if a tie
     console.log("in sortedPlayers1.sort")
     console.log("sortedPlayers2 " + a.scoreTied)
     console.log("sortedPlayers2 " + b.scoreTied)
     var scoreTiedSet = false;

     if ((a.scoreTied > 0) && (b.scoreTied > 0)){
        scoreTiedSet = true
     }

     //for a scoreTied situation and you need a custom way of ranking  - example a play off - like in world champs 2024
      if (a.scoreTied < b.scoreTied ){
        return -1;
      }
      if (b.scoreTied > b.scoreTied){
        return 1
      }

      
      //new player name sort (for wcgc 2023) - check if this is going to messup the general leaderboard. 
     if(scoreTiedSet === false){
      if( a.parScore == b.parScore ){
        let aArray = a.name.toUpperCase().trim().split(" ");
        let aSurname = aArray[1];

        let bArray = b.name.toUpperCase().trim().split(" ");
        let bSurname = bArray[1];

        //feb 2023 enhanced to sort on firstname if surnames match
        if (aSurname === bSurname){
          let aFirstName = aArray[0];
          let bFirstName = bArray[0];

          if (aFirstName < bFirstName){
            return -1;
          }

          if (aFirstName > bFirstName){
            return 1;
          }
        }


        if (aSurname < bSurname){
          return -1 //-1;
        }
        if (aSurname > bSurname){
          return 1 //1;
        }





      }
    }
 
      //end new sort on player names


     







      
      





      return 0;
    });

    console.log("sorted players:")
    //console.log(sortedPlayers);

    var currentParScore = 0;
    var previousParScore = 0;
    var oldRank = 1;

    var currentScoreTied = 0;
    var previousScoreTied = 0;

    sortedPlayers.forEach(myFunction);






    function myFunction(value, index, array) {
      console.log("Hello I am going to rank the players.");

      console.log(index + ':' + value.parScore);

      /* if using T1, T2 etc etc */
      if (index == 0) {
        currentParScore = value.parScore;
        previousParScore = 0;

        currentScoreTied = value.scoreTied
        previousScoreTied = 0
      }

      if (index > 0) {
        if (value.parScore >= currentParScore) {
          previousParScore = currentParScore;
          currentParScore = value.parScore;

          previousScoreTied = currentScoreTied;
          currentScoreTied = value.scoreTied;
        }
      }


      console.log("current par score? : " + currentParScore);
      console.log("previous par score? : " + previousParScore);

      if (currentParScore > previousParScore) {
        sortedPlayers[index].rank = index + 1;
        oldRank = index + 1;
      } else {
        sortedPlayers[index].rank = oldRank;
      }

      //end if using T1, T2 etc



      //remove line below if not using Tie ranking
     // sortedPlayers[index].rank = index + 1;

     //if a scoreTied is set then this will automatically not use tie ranking.

     if (currentScoreTied > 0 && currentScoreTied != previousScoreTied){
      sortedPlayers[index].rank = index + 1;
     }
    
     




      // break; //Stop this loop, we found it!


    }

    console.log(sortedPlayers);


//test removing gameid's
//ha this works, remving them after the sort.
if(this.state.paramSubGameId){
var i3 = sortedPlayers.length
console.log("filtering the array for dead players." + sortedPlayers.length)
 while (i3--) {
   if (sortedPlayers[i3].gameId != this.state.paramSubGameId) {
       sortedPlayers.splice(i3, 1);
   }
 }
}
//end test



    if (loading) {
      return (
        <div>
          Loading...
        </div>
      )
    }

    if (error) {
      return (
        <div className="alert alert-danger">
          Error: {error}
        </div>
      )
    }



    return (







      <div className="container-fluid">





        <div className="row p-3 row-no-gutters">

{/*May 2023 we do not render the course icon column 1 in xs or s classes but only from the middle  this is what d-none d-md-block does */}


          <div class="col-1 d-none d-md-block ">&nbsp;</div>
          <div class="col-1 d-flex justify-content-center "></div>
          <div class="col-1">&nbsp;</div>
            {/* may2023 becasue we hide the first column on small screens if we rename col-4 to just col then this
          will have the effect of making the player name column use that extra column if available*/}
          <div class="col">
          <div class="fontColumnTitle size3  h-100  rounded aligntextcenterleft  ">Entries: {sortedPlayers.length} </div>
</div>
        
     


          <div class="col-1">
            <div class="fontColumnTitle sizeColumnHeader  h-100  rounded aligntextcentercenter">Hole</div>
          </div>

          <div class="col-1">
            <div class="fontColumnTitle  sizeColumnHeader  h-100  rounded aligntextcentercenter">Score</div>
          </div>
          <div class="col-1">
            <div class="fontColumnTitle  sizeColumnHeader  h-100  rounded aligntextcentercenter">Par</div>
          </div>

<div class="col-1"></div>
<div class="col-1 d-none d-md-block"></div>



        </div>




        {sortedPlayers.map(this.renderPlayer)}





      </div>
    )

  }
 
}

export default connect(mapStateToProps, { loadPlayers, onPlayerCreate, onPlayerUpdate, onPlayerRemove })(Players);



export const numPlayers = Players.staticMethod

/*
const withStore = BaseComponent => props => {
  const store = useStore();
  return <BaseComponent {...props} store={store} />;
};

const MyClass = withStore(Players)

*/

//export const numPlayers = "71"
//export {this.state.paramNumPlayers}