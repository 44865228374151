import { combineReducers } from 'redux'

import players from './players'

const rootReducer = combineReducers({
  players
});

export default rootReducer;

export const getPlayers = state => state.players;