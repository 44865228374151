import Backendless from 'backendless'

import t from '../action-types';
import { getPlayers } from '../reducers';


import { globalTournamentName} from '../../globalvariables/globalvariables.js';

import { globalPlayerNumber } from '../../globalvariables/globalvariables';

import {useLocation} from "react-router-dom";
import { createImportSpecifier, isGetAccessor, isVariableDeclarationList } from 'typescript';


//port { createContext } from 'react'

//import React, {useState} from 'react';

/*
export const loadPlayers = () => ({
  types  : [t.LOAD_PLAYERS, t.LOAD_PLAYERS_SUCCESS, t.LOAD_PLAYERS_FAIL],
  apiCall: () => Backendless.Data.of('Gamifers').find(),
});
*/

function pad(n) {
  return (n < 10) ? ("0" + n) : n;
}

//aug 2022
//get the tournament information






/*
(async () => {

  var mikey = ""
  try {
    console.log("right lets go ");
    const subQueryBuilder = Backendless.DataQueryBuilder.create();
    subQueryBuilder.setPageSize(10);
    var subWhereClause = "tournamentId = 'WCGN2023'";
    subQueryBuilder.setWhereClause(subWhereClause);
    console.log("in this weird thing!");
    let theGame = await Backendless.Data.of('Games').find(subQueryBuilder);
    console.log("weird thing, theGame is " + theGame);
    console.log(theGame[0].tournamentName);
   // process.env.REACT_APP_TOURNAMENT_NAME = theGame[0].tournamentName;
   //this.globalTournamentName = "Will not change" ; //theGame[0].tournamentName;
   mikey = theGame[0].tournamentName;
  
  


  } catch (err) {
    console.log(err);
  }
})();
*/



function resolveAfter2Seconds() {
  return new Promise(resolve => {
    setTimeout(() => {
     // resolve("resolved");

     const something = {
      objVar: "1234"
    };
    resolve(something)


    }, 2220);
  });
}



/*
export const tournamentName2 = () => {

  //const result = await resolveAfter2Seconds();
  const mikey = "cool"

      let storedData = "Mikey"

        const something = {
        objVar: storedData
      };

    return something


    }
*/


//export default NumberContext


/*
export const tournamentName3 = async function(){

  const result = await resolveAfter2Seconds();
  //const mikey = "cool"
  console.log("result is " + result)
  const { objVar } = result
  console.log(objVar)


  

  return (
    <UserContext.Provider value="Reed">
     <User/>
    </UserContext.Provider>
  );


    }
*/
    


export const tournamentName2 = async function(){

  const result = await resolveAfter2Seconds();
  //const mikey = "cool"
  console.log("result is " + result)
  const { objVar } = result
  console.log(objVar)

      let storedData = result

        const something = {
        objVar: storedData
      };

    return result


    }





/*
  var whereClause = "tournamentId = 'WCGN2023'";
  var queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
  
  Backendless.Data.of( "Games" ).find( queryBuilder )
   .then( function( theGame ) {
      // every loaded object from the "Contact" table is now an individual plain JS object
      console.log("found some Games!!!");
      console.log(theGame[0].tournamentName);
      const something = {
  
        objVar: "closer"
      };
  
  
      return something

    })
   .catch( function( fault ) {
      // an error has occurred, the error code can be retrieved with fault.statusCode
    });

*/





  //var mikey = "wow"
/*
  const something = {
  
      objVar: mikey
    };


    return something
    */
  



/*
export const tournamentName2 = () => {



var mikey = "wow"

const something = {

    objVar: mikey
  };
  return something
  
}
*/

/*
export const tournamentName2 = () => {

  (async () => {

   var mikey = "in async"
    try {
      console.log("right lets go ");
      const subQueryBuilder = Backendless.DataQueryBuilder.create();
      subQueryBuilder.setPageSize(10);
      var subWhereClause = "tournamentId = 'WCGN2023'";
      subQueryBuilder.setWhereClause(subWhereClause);
      console.log("in this weird thing!");
      let theGame = Backendless.Data.of('Games').find(subQueryBuilder);
      console.log("weird thing, theGame is " + theGame);
      console.log(theGame[0].tournamentName);
     // process.env.REACT_APP_TOURNAMENT_NAME = theGame[0].tournamentName;
     //this.globalTournamentName = "Will not change" ; //theGame[0].tournamentName;
       mikey = "wow"
      mikey = theGame[0].tournamentName;
      
  
  const something = {
  
      objVar: mikey
    };
    return something
    
  
  
    } catch (err) {
      console.log("problem!!")
      console.log(err);
    }
  })();





    
  }

  

*/



/*
export const loadTournament = () => ({


  "tournamentName": "M"
 

})





//export const loadTournament = () => 'bar'
/*
export function loadTournament(){
  return 'bar';
  
}
*/




//export const loadTournament = "WHOA2";

/*
function FetchData() {
  console.log("fetching tournament data....")

   // var tournamentName2 = this.state.tournamentName;
  //console.log(tournamentName2);
  const [tournamentName2, setTournament] = useState('World Crazies!');


}
*/

export const loadPlayers = (gameId, date, rank, playing, course, scoreVerified, tournamentId, activeTournament) => ({
  types  : [t.LOAD_PLAYERS, t.LOAD_PLAYERS_SUCCESS, t.LOAD_PLAYERS_FAIL],
  apiCall: function() {      //march 2022 changed this wich I find hard to read to this apiCall: () => {  , this means you can put async in to allow await futher down the code.
 
  const queryBuilder = Backendless.DataQueryBuilder.create();
// queryBuilder.setSortBy( ["parScore"] );
  queryBuilder.setPageSize(100);

  //need to find the query param here
 var whereClause = "";
 var clauseIsSet = false;
 console.log("whereClause at beginning...")



 /*
 do not put this in the where clause, this will prevent ones which have been changed after the initial load event being shown
 instead do like the ranks in the render event and just ignore if not valid if paramater set
 if(scoreVerified){
  console.log("Yep loadPlayers: have a scoreVerified condition")

  if(clauseIsSet == false){
    whereClause = "(verified = 'TRUE')";
    clauseIsSet = true;
  }
    else{
      whereClause = whereClause + " AND (verified = 'TRUE')";
    };




}
*/

//April18 2023
//we don't want any players with an error.
whereClause = "GamingStatus != 'error'"
clauseIsSet = true;

//April25 2023 - we keep gameid in here, but we also have another subgameid which works like gameid
//but it takes them out after the sort so that we can retain the ranking position within the tournament etc.



  if(gameId){
    console.log("Yep loadPlayers here we are: have a gameId")

    if(clauseIsSet == false){
      whereClause = "(gameId = '" + gameId + "')";
      clauseIsSet = true;
    }
      else{
        whereClause = whereClause + " AND (gameId = '" + gameId + "')";
      };



  
  }



console.log("whereClause after gameId" + whereClause)
//queryBuilder.setWhereClause(whereClause); this is not being set

      



  
      console.log("activeTournament : " + activeTournament)


      if(activeTournament && !tournamentId){
        console.log("Yep loadPlayers: have a active tourament")
        if(clauseIsSet == false){
          whereClause = "(activeTournament = '" + activeTournament + "')";
          clauseIsSet = true;
        }
          else{
            whereClause = whereClause + " AND (activeTournament = '" + activeTournament + "')";
          };
        
      }

    

if(!activeTournament && !tournamentId){
  //we don't show non active tournaments by default.
  console.log("Yep loadPlayers: have a active tourament")
  if(clauseIsSet == false){
    whereClause = "(activeTournament = 'true')";
    clauseIsSet = true;
  }
    else{
      whereClause = whereClause + " AND (activeTournament = 'true')";
    };
  
}


//try setting a sate hook
//FetchData()



if(tournamentId && !activeTournament){
  console.log("Yep loadPlayers: have a tournamentId");
  
  /*  this works but not implemented, because even if we use this, then when the default current tournament id changes
  we would have to do another pull request here and that would mean a refresh on the page. so instead we will have a field calldd
  current = true in the gamifers table and use that instead. 
  if(tournamentId === "current"){
    console.log("right lets go ");
    const subQueryBuilder = Backendless.DataQueryBuilder.create();
    subQueryBuilder.setPageSize(10);
    var subWhereClause = "tournamentCounter = 71";
    subQueryBuilder.setWhereClause(subWhereClause);
    console.log("in this weird thing!");
    let theGame =  await Backendless.Data.of('Games').find(subQueryBuilder);
    console.log("weird thing, theGame is " + theGame);
    console.log(theGame[0].tournamentId)
  }
  */
 
  //can't use window.tournamentName as it isn't availble quickly enough
  //can't use process.evn.REACT_APP_TOURNAMENT_NAME as you can't set it or update in code

//enclose in this funny block to allow for async and await call.

/*
  (async () => {
    try {
      console.log("right lets go ");
      const subQueryBuilder = Backendless.DataQueryBuilder.create();
      subQueryBuilder.setPageSize(10);
      var subWhereClause = "tournamentId = 'WCGN2023'";
      subQueryBuilder.setWhereClause(subWhereClause);
      console.log("in this weird thing!");
      let theGame = await Backendless.Data.of('Games').find(subQueryBuilder);
      console.log("weird thing, theGame is " + theGame);
      console.log(theGame[0].tournamentName);
     // process.env.REACT_APP_TOURNAMENT_NAME = theGame[0].tournamentName;
     this.globalTournamentName = "Will not change" ; //theGame[0].tournamentName;
    



    } catch (err) {
      console.log(err);
    }
  })();
*/



  
console.log("continuing");
  

  if(clauseIsSet == false){
    whereClause = "(tournamentId = '" + tournamentId + "')";
    clauseIsSet = true;
  }
    else{
      whereClause = whereClause + " AND (tournamentId = '" + tournamentId + "')";
    }
}

if(tournamentId && activeTournament){
  console.log("Yep loadPlayers: have a active tourament")
  if(clauseIsSet == false){
    whereClause = "(tournamentId = '" + tournamentId + "') AND (activetournament = '" + activeTournament + "')";
    clauseIsSet = true;
  }
    else{
      whereClause = whereClause + " AND (tournamentId = '" + tournamentId + "') AND (activeTournament = '" + activeTournament + "')";
    };
  
}



 if(course){
  console.log("Yep loadPlayers: have a course")
  if(clauseIsSet == false){
    whereClause = "(courseName = '" + course + "')";
    clauseIsSet = true;
  }
    else{
      whereClause = whereClause + " AND (courseName = '" + course + "')";
    };
  
}

  if(date){
    console.log("Yep loadPlayers: date param is set")

    var dateClause = "";

    //helper param today
 

    if(date.includes('-')){
      console.log("yep date includes -")
      //05/07/21-17/07/21
//get first part
      var firstDateParam = date.substring(0,10);
      console.log("firstDateParam" + " " + firstDateParam);

      var dateParts = firstDateParam.split("/");
      
      // month is 0-based, that's why we need dataParts[1] - 1
      var date1 = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
      var month = pad(date1.getMonth() + 1);  //zero based!  - the pad bit uses the function to put a leading zero on single digites
      
      console.log("month is");
      console.log(month)
      
      var day = pad(date1.getDate());
      var year = date1.getFullYear();


      var secondDateParam = date.substring(date.indexOf('-') + 1);
      console.log("secondDateParam" + " " + secondDateParam);

      var dateParts2 = secondDateParam.split("/");
      
      // month is 0-based, that's why we need dataParts[1] - 1
      var date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]); 
      date2.setDate(date2.getDate()+1);  //necessary to add a day!
      var month2 = pad(date2.getMonth() + 1);  //zero based!
      var day2 = date2.getDate();
     // day2 = day2 -1;
      day2 = pad(day2);
      var year2 = date2.getFullYear();

      var strDate2 = month2 + "-" + day2 + "-" + year2;



      var strDate = month + "-" + day + "-" + year;
      console.log(month);
      console.log(day);
      console.log(year);

      console.log("strDate is ");
      console.log(strDate)
      console.log("strDate2 is ");
      console.log(strDate2)


      //whereClause = "dateTime >= '" + "07-03-2021" + "'";





  
      if(clauseIsSet == false){
        whereClause = "(dateTime >= '" + strDate + "' and dateTime < '" + strDate2 + "')";
        clauseIsSet = true;
      }
        else{
          whereClause = whereClause + " AND (dateTime >= '" + strDate + "' and dateTime < '" + strDate2 + "')";
        };

    }

    if(!date.includes('-') && date!='today'){
      console.log("yep date does not includes -")
      //eg 12/07/2021
//get first part
var firstDateParam = date.substring(0,10);
console.log("firstDateParam" + " " + firstDateParam);

var dateParts = firstDateParam.split("/");

// month is 0-based, that's why we need dataParts[1] - 1
var date1 = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
var month = pad((date1.getMonth() + 1));  //zero based!
var day = pad(date1.getDate());
var year = dateParts[2]; //date1.getFullYear();

console.log(month);
console.log(day);
console.log(year);

var strDate = month + "-" + day + "-" + year;
console.log("strDate for part 1 is ");
console.log(strDate)


var date2 = new Date(date1);
date2.setDate(date1.getDate()+1);
console.log("date2 is" + date2);



var month2 = pad(date2.getMonth() + 1);  //zero based!
var day2 = pad(date2.getDate());
var year2 = date2.getFullYear();
var strDate2 = month2 + "-" + day2 + "-" + year2;




if(clauseIsSet == false){
  whereClause = "(dateTime >= '" + strDate + "' and dateTime <= '" + strDate2 + "')";
  clauseIsSet = true;
}
  else{
    whereClause = whereClause + " AND (dateTime >= '" + strDate + "' and dateTime <= '" + strDate2 + "')";
  };


}


    if(date == 'today'){
      console.log("date is today");
      var date1 = new Date() //today
      var month = pad(date1.getMonth() + 1);  //zero based!
      var day = pad(date1.getDate());
      var year = pad(date1.getFullYear());

      var strDate = month + "-" + day + "-" + year;
      console.log(month);
      console.log(day);
      console.log(year);

      console.log("strDate is ");
      console.log(strDate)

 

      if(clauseIsSet == false){
        whereClause = "(dateTime >= '" + strDate + "')";
        clauseIsSet = true;
      }
        else{
          whereClause = whereClause + " AND (dateTime >= '" + strDate + "')";
        };


    } 



//console.log("whereClause is " + whereClause);

  //  queryBuilder.setWhereClause(whereClause);
  }

  console.log("at end whereClause is " + whereClause);
  queryBuilder.setWhereClause(whereClause);




  return Backendless.Data.of('Gamifers').find(queryBuilder);},

  



});











export const createPlayer = player => ({
  types  : [null, t.CREATE_PLAYER_SUCCESS, null],
  apiCall: () => Backendless.Data.of('Gamifers').save(player),
});

export const updatePlayer = player => (dispatch, getState) => {
  const players = getPlayers(getState()).list
  const prevPlayer = players.find(p => p.objectId === player.objectId)


  console.log("HERE IN UPDATE PLAYER!!!");

  return dispatch({
    player,
    prevPlayer,
    types  : [t.UPDATE_PLAYER, t.UPDATE_PLAYER_SUCCESS, t.UPDATE_PLAYER_FAIL],
    apiCall: () => Backendless.Data.of('Gamifers').save(player),
  })
};

export const removePlayer = playerId => ({
  playerId,
  types  : [null, t.REMOVE_PLAYER_SUCCESS, null],
  apiCall: () => Backendless.Data.of('Gamifers').remove(playerId),
});

export const onPlayerCreate = player => ({
  player,
  type: t.ON_PLAYER_CREATE,
});

export const onPlayerUpdate = player => ({
  player,
  type: t.ON_PLAYER_UPDATE,
});

export const onPlayerRemove = player => ({
  player,
  type: t.ON_PLAYER_REMOVE,
});

//export const TournamentContext = createContext(tournamentNameGlob);

