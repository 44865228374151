//import React from 'react';
//changed to
//import React, {useState} from 'react';

import Players from './players';
import Alerts, { addAlert } from './alerts';

import { useLocation } from 'react-router-dom';  //mikequery

import useSWR from 'swr';

//import { globalPlayerNumber } from './globalvariables/globalvariables';
//import {numPlayers } from './players/index';


//import { globalTournamentName} from './globalvariables/globalvariables.js';

/*import {globalTournamentName} from './globalvariables/globalvariables'
//you cannot change globalTournamentNames outside of the module so don't think this is going to work//*/

//import {TournamentContext } from './store/actions/players.js';

//import { globalTournamentName} from './globalvariables/globalvariables.js';

import { tournamentName2, tournamentName3, something} from './store/actions/players'

//import UserContext from './store/actions/players'
//import UserContextProvider from './store/actions/players'
//import NumberContext from './store/actions/players'

//import useStore from './zStore'  //using zStore rather than directly below:
//import create from 'zustand'
//import People from './components/People'
import {numPlayers} from './players';

window.addAlert = addAlert



export default function App() {

/*
  const useStore = create((set) => ({
    playerCount: 9,
    tuna: 2,
   
  }))
  */


  /*
  const useStore = create(set => ({
    playerCount: 25,
  }));
  */
  
  //const playerCount = useStore(state => state.playerCount)
  //const addPlayer = useStore(state => state.addPlayer)
 


  const search = useLocation().search;  // mikequery
  const param_game_id = new URLSearchParams(search).get('gameid');  
  const param_tournament_id = new URLSearchParams(search).get('tournamentid');
  const param_id = new URLSearchParams(search).get('id');
  const param_active_tournament = new URLSearchParams(search).get('activetournament');


  const param_tournament_name = new URLSearchParams(search).get('tournamentname');
  const param_rank = new URLSearchParams(search).get('rank'); 
  const param_playing = new URLSearchParams(search).get('playing'); 
  const param_date = new URLSearchParams(search).get('date'); 
  const param_course = new URLSearchParams(search).get('course'); 
  const param_headless = new URLSearchParams(search).get('headless');

 //console.log("gameid is" + param_game_id);  //mikequery

/*
 function User() {
  const value = React.useContext(UserContextProvider);  
    
  return <h1>{value}</h1>;
}
*/

//console.log("playerCount (exported)" + numPlayers);









  function ParamGameIdTrue(props) {
    return  <div class="headerFilter1  aligntextcentercenter">Game : {param_game_id}</div>;
  }

 
 const fetcherJSON = (...args) => fetch(...args).then(res => res.json())
    const { data, error } = useSWR("https://api.backendless.com/E9DF3457-FBDF-4D42-A6F3-FDFF2C260FF6/5CE20308-B382-0C07-FFDC-6197A51DB400/data/Games?where=tournamentId='" + param_tournament_id + "'", fetcherJSON)
    if (error) return <div>Failed to load</div>
    if (!data) return <div>Loading...</div>
 console.log("we got data!!!");
 //console.log(data);
//console.log(data[0].tournamentName);
 
 
  function ParamTournamentIdTrue(props) {

    


    const { objVar } = tournamentName2()
    console.log("and here in app.js")
    console.log(objVar)
    
    console.log("what is tournament?");

    var tournamentName = ""  //we can't find this.
    if (data.length > 0){
        tournamentName = data[0].tournamentName;
    }
    
    
    
    
    
        var displayName = ""
        if(param_tournament_name){
          displayName = "@" + param_tournament_name;
          displayName = displayName.toUpperCase();
        }else{
          displayName = tournamentName; 
          displayName = displayName.toUpperCase();
        }
       
        return  <div class="  aligntextcentercenter">{displayName}</div>;


  }


  function DisplayTournamentCourse(props) {
var tournamentName = ""
if (data.length > 0){
 tournamentName = data[0].courseFilter;
}
    
    
        var displayName = ""
   
        displayName = tournamentName; 
        displayName =  displayName.toUpperCase();
       
               return  <div class="  aligntextcentercenter">{displayName}</div>;
  }

  function DisplayTournamentDate(props) {

    var tournamentDate = ""
    if (data.length > 0){
      tournamentDate = data[0].friendlyDateDisplay;
    }
   
    
        var displayName = ""
   
        displayName = tournamentDate; 
        displayName =  displayName.toUpperCase();
       
               return  <div class="  aligntextcentercenter">{displayName}</div>;
  }

  function ParamTournamentNameTrue(props) {

//const tournamentName = useContext(TournamentContext)
//const tournamentName = globalTournamentName;


//const tournament = loadTournament; 
//const tournamentName = tournament.tournamentName;
//const tournieName = tournamentName
const { objVar } = tournamentName2()
console.log("and here in app.js")
console.log(objVar)

console.log("what is tournament?");
//var tournamentName = data[0].tournamentName;




    var displayName = ""
    if(param_tournament_name){
      displayName = "@" + param_tournament_name;
      displayName = displayName.toUpperCase();
    }else{
      displayName = "GOLF SCORES @ MINI GOLF"
    }
    return  <div class="  aligntextcentercenter">{displayName}</div>;
  }

  function ParamGameIdFalse(props) {
    return <p></p>;
  }
  function ParamTournamentIdFalse(props) {
    return <p></p>;
  }

  function ParamTournamentNameFalse(props) {
    return <p></p>;
  }

  function ParamPlayerNumber(props){
    //addPlayer()
    //console.log("playerCount : " + playerCount);
    //return <p>{playerCount}</p>
  }

  function ShowParamGameId(props) {
    if (param_game_id) {
      return <ParamGameIdTrue />;
    }
    return <ParamGameIdFalse />;
  }

  function ShowParamTournamentId(props) {
    if (param_tournament_id) {
      return <ParamTournamentIdTrue /> ;
    }
    return <ParamTournamentIdFalse />;
  }

  function ShowPlayerNumber(prop){
    return <ParamPlayerNumber />;
  }

  function ShowParamTournamentName(props) {
    if (param_tournament_name) {
      return <ParamTournamentNameTrue />;
    }
    return <ParamTournamentNameFalse />;  //MARCH 2022 In fact we always return true now // return <ParamRankFalse />;
  }

  function ParamRankTrue(props) {
        var displayRank = ""

        if(param_rank){
          const myArray = param_rank.split("-")

          let firstRank = myArray[0];
          let secondRank = myArray[1];

          if (firstRank == '1'){
            displayRank = 'TOP ' + secondRank;
          }else{
            displayRank = "RANK " + param_rank;
          }
      }else{
        displayRank = ""
      }

    return  <div class="aligntextcentercenter">{displayRank}</div>;
  }



  function ParamRankFalse(props) {
    return <p></p>;
  }

  function ShowHeader(props){
    if(param_headless == 'true'){
      return "d-none"
    }else{
      return ""
    }
    
  }


  function ShowParamRank(props) {
    if (param_rank) {
      return <ParamRankTrue />;
    }
    return <ParamRankTrue />;  //MARCH 2022 In fact we always return true now // return <ParamRankFalse />;
  }

  function ParamPlayingTrue(props) {
    return  <div class="headerFilter1 aligntextcentercenter">{param_playing}</div>;
  }
  function ParamPlayingFalse(props) {
    return <p></p>;
  }
  function ShowParamPlaying(props) {
    if (param_playing) {
      return <ParamPlayingTrue />;
    }
    return <ParamPlayingFalse />;
  }

  function ParamDateTrue(props) {
    var displayDate = ""
    if (param_date){
displayDate = param_date.toUpperCase()
    }
    return  <div class="aligntextcentercenter"> {displayDate}</div>;
  }
  function ParamDateFalse(props) {
    return <p></p>;
  }
  function ShowParamDate(props) {
    if (param_date) {
      return <ParamDateTrue />;
    }

    if (param_tournament_id) {
      return <DisplayTournamentDate />;
    }


    return <ParamDateFalse />;
  }

  function ParamCourseTrue(props) {
    //eg. &course=Hastings
    var courseName = ""
    if(param_course){
      courseName = param_course;
      courseName = courseName.toUpperCase();
    }else{
      courseName = "?"
    }
    return  <div class="  aligntextcentercenter">{courseName}</div>;



    return  <div class="aligntextcentercenter"> {param_course}</div>;
  }
  function ParamCourseFalse(props) {
    return <p></p>;
  }

  function ShowParamCourse(props) {
    if (param_course) {
      return <ParamCourseTrue />;
    }

    if (param_tournament_id) {
      return <DisplayTournamentCourse />;
    }



    return <ParamCourseFalse />;   //MARCH 2022 In fact we always return true now // return <ParamRankFalse />;
  }


function ShowSubTournaments(props){

    var year = 2020
  if (param_tournament_id == 'WCGC2025' ||  param_tournament_id == 'WCGN2025' ||  param_tournament_id == 'WCGT2025' ||  param_tournament_id == 'WCGJ2025' ){
    year = 2025
  }

  if (param_tournament_id == 'WCGC2024' ||  param_tournament_id == 'WCGN2024' ||  param_tournament_id == 'WCGT2024' ||  param_tournament_id == 'WCGJ2024' ){
    year = 2024
  }

  if (param_id == 'world' && year == 2025){
  
    
    return <div className="row ">
    <div class="col-2 zTop"><p class="filterRowBackgroundColor  "></p></div>


   
          <div class="col-8"><p class="filterRowBackgroundColor aligntextcentercenter ">
            <a href="?id=world&tournamentid=WCGC2025" button type="button" class="btn btn-info btn-sm text-white m-1" >Championship</a>
            <a href="?id=world&tournamentid=WCGN2025" button type="button" class="btn btn-info btn-sm text-white m-1" >Novice</a>
            <a href="?id=world&tournamentid=WCGJ2025" button type="button" class="btn btn-info btn-sm text-white m-1" >Junior</a>
            <a href="?id=world&tournamentid=WCGT2025" button type="button" class="btn btn-info btn-sm text-white m-1" >Team</a>


         </p></div>
        
         
          <div class="col-2  "><p class="filterRowBackgroundColor  "></p></div>                     
    


    </div>
  }

  if (param_id == 'world' && year == 2024){
  
    
    return <div className="row ">
    <div class="col-2 zTop"><p class="filterRowBackgroundColor  "></p></div>


   
          <div class="col-8"><p class="filterRowBackgroundColor aligntextcentercenter ">
            <a href="?id=world&tournamentid=WCGC2024" button type="button" class="btn btn-info btn-sm text-white m-1" >Championship</a>
            <a href="?id=world&tournamentid=WCGN2024" button type="button" class="btn btn-info btn-sm text-white m-1" >Novice</a>
            <a href="?id=world&tournamentid=WCGJ2024" button type="button" class="btn btn-info btn-sm text-white m-1" >Junior</a>
          

         </p></div>
        
         
          <div class="col-2  "><p class="filterRowBackgroundColor  "></p></div>                     
    


    </div>
  }





  else{
      return <div className="row"></div>
  }
  
}

  
function ShowCourseImage(props){
/*
if (param_tournament_id == 'WCGN2023' || param_tournament_id == 'WCGC2023' || param_tournament_id == 'WCGJ2023' || param_tournament_id == 'WCGT2023'){
  return  <img src="gameboard_world_crazy.png" class="clientlogosize2 "></img>
}
return  <img src="gameboard2.png" class="clientlogosize2 "></img>
  */

if (param_id == 'world'){
  return  <img src="gameboard_world_crazy.png" class="clientlogosize2 "></img>
}
if(param_tournament_id == "celebrity"){
  return  <img src="gameboard_celebrity.png" class="clientlogosize2 "></img>
}
return  <img src="gameboard2.png" class="clientlogosize2 "></img>

}


  return (

/*
    <div>
    <div className="container-fluid colorHeader">
        <div className="container">
            <div className="row pb-1">
            
     
            <div class="col-2">
              <img src="client_logo.png"  alt="join us" class="clientlogosize float-right"  />
             </div>
            
           
              
              <div class="col-10">

               
                <div class="row">
                  <div class="col-5">
                      <p class="headerTitle">Leaderboard</p>
                  </div>
                  <div class="col-3 mt-3 ">
                  <ShowParamRank />
                  </div>
                  <div class="col-4 mt-3">
                   
                    <ShowParamGameId />
                     
                  </div>



                  </div>
                  <div class="row">
                  <div class="col-4 ">
                  <ShowParamDate />
                      
                  </div>
                  
                  
                  <div class="col-4">
                  <ShowParamCourse />

                   
                     
                  </div>


                  <div class="col-3 ">
                  <ShowParamPlaying />
                  </div>

                  <div class="col-1 ">
                  <ShowParamPlaying />
                  </div>

                </div>
               
              </div>
         
            
           
           
            </div>
            
</div>
</div>


 <div class="col-2 colorBlue">
                          <img src="shield_export.png"  alt="join us" class="clientlogosize mx-auto d-block"  />
                        </div>

 <a class="filterRowBackgroundColor " href="?id=world&tournamentid=WCGC2023">&nbsp;Championship&nbsp;</a> | <a href="?id=world&tournamentid=WCGN2023">&nbsp;Novice&nbsp;</a> | <a href="?id=world&tournamentid=WCGJ2023">&nbsp;Junior&nbsp;</a> | <a href="?id=world&tournamentid=WCGT2023">&nbsp;Team&nbsp;</a>


*/



<div>
<div className={"container-fluid " + "colorHeader " + ShowHeader() }>
    <div className="container-xl">
       <ShowSubTournaments />
        <div className="row pb-1 " >
                      <div class="col-3 my-auto ">
                           </div>
                        <div class="col-6 mx-auto text-center centeralignimage ">
                      <ShowCourseImage />
                        </div>
              
              <div class="col-3 my-auto">
                                        <div className="row">
                                          </div>
              </div>
        </div>

        <div className="row ">
        <div class="col-3 "><p class="filterRowBackgroundColor  "><ShowParamRank /></p></div>
              <div class="col-3  "><p class="filterRowBackgroundColor  "><ShowParamTournamentId /> {window.tournamentName}</p></div>
              <div class="col-3  "><p class="filterRowBackgroundColor  "><ShowParamCourse /></p></div>
             <div class="col-3  "><p class="filterRowBackgroundColor  "><ShowParamDate /></p></div>                     
             </div>
            </div>
        </div>



          <Players/>
          <Alerts/>

      

          <div className="row" id="pageLoaded">
        <div class="col-12 "><p></p></div>



                              
            


        </div>




        </div>





    
  );
}
