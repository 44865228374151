import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'





//reactrouterstuff
import { BrowserRouter } from "react-router-dom";

import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';

import { createStore } from './store'
import Backendless from 'backendless'

console.log("what app is this?")
const appname = new URLSearchParams(window.location.search).get("id")
console.log(appname)


if (appname == 'world'){
Backendless.initApp('E9DF3457-FBDF-4D42-A6F3-FDFF2C260FF6', '342F74CE-A8D8-67DE-FF6B-48C22ACDF400');
}else if (appname == 'ha'){
  Backendless.initApp('E9DF3457-FBDF-4D42-A6F3-FDFF2C260FF6', '342F74CE-A8D8-67DE-FF6B-48C22ACDF400');
}else{
  Backendless.initApp('', '');  //crash quietly
}


const rootElement = document.getElementById('root');




ReactDOM.render(
  <Provider store={createStore()}>
   <BrowserRouter> 
   <App/>
   </BrowserRouter>
  </Provider>,
  rootElement
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
